import React from 'react';

import './index.sass';

export default function Footer() {
  return (
    <footer>
      2018-{(_ => new Date().getFullYear())()} Stichting Open Research Foundation <br/>
      Utrechtseweg 34, 3818 EL Amersfoort, Netherlands
    </footer>
  );
}
