import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';

import openMenu from '../../assets/openMenu.png';
import closeMenu from '../../assets/closeMenu.png';

import './index.sass';

export default function Nav(props) {
  const [isActive, toggle] = useState(0);

  return (
    <nav className={props.isLanding && !isActive ? 'p3' : isActive ? 'active' : ''}>
      <div className='link logo' onClick={isActive ? toggle.bind(this, !isActive) : _=>{}}>
        <Link to='/about'>
          <img src={logo}/>
          <img  className='mobile-logo' src={mobileLogo}/>
        </Link>
      </div>
      <svg className={isActive ? "toggle-menu-button active" : "toggle-menu-button"} 
        viewBox="0 0 100 100" 
        width="40" 
        onClick={toggle.bind(this, !isActive)}>
        <path
            className="line top"
            d="m 30,33.3 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
        <path
            className="line"
            d="m 30,50 h 40" />
        <path
            className="line bottom"
            d="m 30,66.6 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
      </svg>
      <div className='main'>
        <div className='link' onClick={toggle.bind(this, 0)}>
          <Link to='/about'>About</Link>
        </div>
        <div className='link' onClick={toggle.bind(this, 0)}>
          <Link to='/mission'>Mission</Link>
        </div>
        <div className='link' onClick={toggle.bind(this, 0)}>
          <Link to='/approach'>Approach</Link>
        </div>
        <div className='link' onClick={toggle.bind(this, 0)}>
          <Link to='/leadership'>Leadership</Link>
        </div>
        <div className='link blue' onClick={toggle.bind(this, 0)}>
          <Link to='/contact'>Join Us</Link>
        </div>
      </div>
    </nav>
  );
}

