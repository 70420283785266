import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Views
import About from './components/Views/About';
import Mission from './components/Views/Mission';
import Approach from './components/Views/Approach';
import Leadership from './components/Views/Leadership';
import Contact from './components/Views/Contact';
import FZF from './components/Views/FZF';

import './App.sass';

import bg1 from './assets/bg1.jpg';
import bg2 from './assets/bg2.jpg';
import bg3 from './assets/bg3.jpg';
import bg4 from './assets/bg4.jpg';
import bg5 from './assets/bg5.jpg';

function App() {
  function preloadImages(urlList) {
      let fragment = document.createDocumentFragment(); 
      for (let i = 0; i < urlList.length; i++) {
          const imgUrl = urlList[i];
          const linkEl = document.createElement('link');
          linkEl.setAttribute('rel', 'preload');
          linkEl.setAttribute('href', imgUrl);
          linkEl.setAttribute('as', 'image');
          fragment.appendChild(linkEl);
      }
      document.head.appendChild(fragment);
  }
  preloadImages([bg1, bg2, bg3, bg4, bg5]);

  return (
    <div className='App'>
      <Router>
          <Switch>
            <Route exact path='/' component={About}/>
            <Route exact path='/about' component={About}/>
            <Route exact path='/mission' component={Mission}/>
            <Route path='/approach' component={Approach}/>
            <Route exact path='/leadership' component={Leadership}/>
            <Route exact path='/contact' component={Contact}/>
            <Route component={FZF}/>
          </Switch>
      </Router>
    </div>
  );
}

export default App;
