import React, { useState } from 'react';

import firebase from 'firebase';
import "firebase/firestore";

import validator from 'email-validator';

import Background from '../Background';
import Nav from '../Nav';
import Footer from '../Footer';

import bg5 from '../../assets/bg5.jpg';

import './contact.sass';

export default function Contact() {
  
  const [form, setForm] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState({ _type: '', text: 'Submit'});

  const submit = () => {
    const delayedStatusUpdate = async (_type, text) => {
      setTimeout(()=>{
        setStatus({ _type, text})
      }, 1000)
    }

    if(!validator.validate(form.email)){
      setStatus({ _type: 'error', text: 'Invalid Email' });
      delayedStatusUpdate('', 'Subscribe');
      return;
    }
    
    const db = firebase.firestore();
    setStatus({ _type: 'loading', text: 'Submitting' });
    db.collection('messages').add(form).then(_ =>{
      setStatus({ _type: 'success', text: 'Submitted' });
      delayedStatusUpdate('', 'Submit');
      setForm({ name: '', email: '', message: '' });
    }).catch(err => {
      console.log(err);
      setStatus({ _type: 'red', text: 'Error' });
      delayedStatusUpdate('', 'Submit');
    });
  }

  return (
    <div className='contact'>
      <Background 
        src={bg5} 
        srcName={"Antiviral Drug"} 
        srcAuthor={"NIH"}/>
      {console.log('hit')}
      <div className='content-container'>
        <div className='content-wrapper'>
          <div className='content'>
            <Nav/>
            <h1>Get In Touch.</h1>
            <p>
              If you’re working on an open research project, 
              tool, or initiative and you’re in need of funding, 
              contributors, or feedback, please reach out to 
              us by submitting the form below and we’ll get 
              back to you as soon as possible.
            </p>
            <form>
              <input 
                className='name' 
                placeholder='Name' 
                onChange={e => setForm({...form, name: e.target.value})} 
                value={form.name}/>
              <input className='email' placeholder='Email' 
                onChange={e => setForm({...form, email: e.target.value})} 
                value={form.email}/>
              <textarea 
                placeholder='Message' 
                onChange={e => setForm({...form, message: e.target.value})} 
                value={form.message}/>
              <div 
                className={'submit_button ' + status._type} 
                onClick={submit.bind(this)}>
                {status.text}
              </div>
            </form>
            <Footer/>
          </div>
        </div>
      </div>
    </div>
  );
}
