import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from "firebase/app";
import "firebase/firestore";

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyATYCkX2wOWJaRUdsUqBeb6rlNtqAwl8Kc",
  authDomain: "theorf-74e22.firebaseapp.com",
  databaseURL: "https://theorf-74e22-default-rtdb.firebaseio.com",
  projectId: "theorf-74e22",
  storageBucket: "theorf-74e22.appspot.com",
  messagingSenderId: "364059453217",
  appId: "1:364059453217:web:c26ff8117cdbc4f566c060",
  measurementId: "G-Y8WYRXSHLD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
