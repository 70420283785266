import './index.sass';

export default function Background(props) {
  return (
    <div className={props.isLanding ? 'background p3' : 'background'}>
      <div className='image' style={{backgroundImage: 'url(' + props.src + ')'}}></div>
      <div className='source'>
        <b>{props.srcName}</b>
        <br/>
        {props.srcAuthor}
      </div>
    </div>
  );
};
