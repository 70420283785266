import React from 'react';
import { Link } from "react-router-dom";

import Background from '../Background';
import Nav from '../Nav';
import Footer from '../Footer';

import bg2 from '../../assets/bg2.jpg';

import './mission.sass';

export default function Mission() {

  return (
    <div className='mission'>

      <Background 
        src={bg2} 
        srcName={"Neisseria gonorrhoeae Bacteria"} 
        srcAuthor={"NIH"}/>

      <div className='content-container'>
        <div className='content-wrapper'>
          <div className='content'>
            <Nav/>
            <h1>A World of <br/>Collaboration.</h1>
            <p>
              We believe the world’s greatest problems 
              must be solved together. Be it developing 
              treatments to slow down and reverse aging,
              therapies to hault the next pandemic, or 
              pest resistant and higher yielding crops 
              to prevent famine, open research practices 
              can improve the lives of millions by 
              accelerating discovery.
            </p>
            <p className='last-child'>
              Our mission is to further open research 
              efforts among the world’s researchers to 
              stimulate scientific discovery for 
              positive global change. We aim to realize 
              our mission by contributing toward three 
              main focus areas: the formulation of open 
              research standards and protocols, the 
              development of open research tools and 
              platforms, and the funding and formation 
              of open research projects and initiatives. 
              Learn more about our approach <Link to='/approach'>here</Link>.
            </p>
            <Footer/>
          </div>
        </div>
      </div>

    </div>
  );
}
