import React from 'react';

//styles
// import '../../styles/About.sass';

export default function FZF() {
  return (
    <div className='FZF'>
      <h1>404</h1>
    </div>
  );
}
