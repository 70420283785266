import React from 'react';

import Background from '../Background';
import Nav from '../Nav';
import Footer from '../Footer';

import bg4 from '../../assets/bg4.jpg';

import './leadership.sass';

import ryan_img from '../../assets/ryan.jpg';
import david_img from '../../assets/david.jpg';
import farouk_img from '../../assets/farouk.jpg';
import alex_img from '../../assets/alex.jpg';
import simon_img from '../../assets/simon.jpg';

export default function Leadership() {

  const directors = [
    {
      img: ryan_img,
      name: "Ryan Brewster",
      position: "Executive director",
      bio: "Ryan co-founded the ORF after realizing the potential of widespread " + 
      "collaboration in open science, open education, and open source software. Be " + 
      "it designing collaborative software or building open source communities " + 
      "with thousands of members, Ryan finds passion in bringing people together to " + 
      "achieve the extraordinary. Through the ORF, Ryan hopes to significantly " + 
      "accelerate the development of life extension therapies - his ultimate goal " + 
      "since he was 11 years old."
    },
    {
      img: david_img,
      name: "David Furlong",
      position: "Director, Platform Design",
      bio: "David is deeply passionate about social goods. After graduating with a " + 
      "degree in Computer Science and Philosophy from Oxford University, David " + 
      "co-founded and became CTO of DeedMob, a social good technology lab. Today, " + 
      "some of the world’s largest charities, corporations, and governments use " + 
      "DeedMob’s technology to organize charitable efforts more efficiently. In " + 
      "2020, David was named in Forbes Europe 30 under 30 Social Entrepreneurs."
    },
    {
      img: farouk_img,
      name: "Farouk Umar",
      position: "Director, Data Standards and Compliance",
      bio: "Farouk is a data enthusiast with expertise in big data pipelines, data " +
      "analytics, data security, and data compliance. Farouk has worked in both " +
      "startups, such as DueDill, and prominent corporations, such as Sony Music, " +
      "ensuring data security, usability, reputability, and compliance with data " +
      "privacy regulations."
    },
    {
      img: alex_img,
      name: "Alexander Taylor",
      position: "Director, Software Development",
      bio: "Alex found his passion in programming at age 11. After graduating with " + 
      "a degree in Computer Science from the University of California, San Diego, " + 
      "Alex went on to work at tech giants, such as Salesforce and Google. Alex " + 
      "values clean code and enjoys developing intuitive and high-impact software " + 
      "used by many."
    },
    {
      img: simon_img,
      name: "Simon Barducci", 
      position: "Director, Decentralized Systems",
      bio: "Simon is passionate about the potential decentralized systems have in " + 
      "forming fairer social, political, and economic structures. This passion led " + 
      "Simon to co-found Coinbrew, a distributed systems development lab based in " + 
      "Tokyo, Japan. Simon has since worked with and advised leading financial " + 
      "institutions and government agencies in Japan and abroad on distributed " + 
      "systems."
    }
  ];

  return (
    <div className='leadership'>

      <Background 
        src={bg4} 
        srcName={"Cerebellum"} 
        srcAuthor={"NIH"}/>

      <div className='content-container'>
        <div className='content-wrapper'>
          <div className='content'>
            <Nav/>
            <h1>Our Leadership.</h1>
            <p>
              Our board is comprised of a diverse group 
              of technologists passionate about open 
              science with extensive experience in the 
              development of collaborative systems.
            </p>
            <div className='directors'>
              {
                directors.map(director => {
                  return (<div className='director'>
                    <img src={director.img}/>
                    <div className='bio'>
                      <div className='name'>
                        <span className='name'>{director.name}</span>
                        <span className='breaker'>{': '}</span>
                        <span className='position'>{director.position}</span>
                      </div>
                      <p>{ director.bio }</p>
                    </div>
                  </div>);
                })
              }
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    </div>
  );
}
