import React from 'react';

import Background from '../Background';
import Nav from '../Nav';
import Footer from '../Footer';

import bg3 from '../../assets/bg3.jpg';

import './approach.sass';

export default function Approach() {

  return (
    <div className='approach'>

      <Background 
        src={bg3} 
        srcName={"Novel Coronavirus SARS-CoV-2"} 
        srcAuthor={"NIH"}/>

      <div className='content-container'>
        <div className='content-wrapper'>
          <div className='content'>
            <Nav/>
            <h1>Standardize.<br/>Empower.<br/>Support.</h1>
            <p>We aim to effectively increase open research 
              efforts by focusing on the development of 
              three primary areas relating to open research: 
              standards, tools, and funding.</p>

            <p><b>Standardize.</b> Standards follow community 
              values, while setting clear courses of action, 
              helping to keep the open community on the 
              right path. Such standards are necessary to 
              define before effective open research tools 
              may be developed and conditions for open 
              research grants may be put forth.</p>

            <p><b>Empower.</b> Collaboration tools largely 
              dictate the scope at which collaboration 
              may occur; thus, better tools allow for 
              richer collaboration. Such tools built 
              around open research and usability 
              standards can better enable the open 
              community to collaborate more efficiently 
              and effectively.</p>

            <p className='last-child'><b>Support.</b> Much of the world’s research is 
              funded by public grants, and while grantmakers 
              have the power to influence the way grantees 
              conduct and publish their research, these 
              grants too often result in non-collaborative 
              research behind paywalls. The more grants for 
              open research, the more prevalent open 
              research practices are likely to become.</p>
            <Footer/>
          </div>
        </div>
      </div>

    </div>
  );
}
