import React, { useState } from 'react';
import { Link } from "react-router-dom";

import firebase from 'firebase';
import "firebase/firestore";

import validator from 'email-validator';

import Background from '../Background';
import Nav from '../Nav';
import Footer from '../Footer';

import bg1 from '../../assets/bg1.jpg';

import './about.sass';

function Header(props) {
  return (
    <h1>
      <span className={props.isLanding ? 'p1' : ''}>Further</span>
      <br/>
      <span className={props.isLanding ? 'p1' : ''}>Science</span>
      <span className={props.isLanding ? 'p2' : ''}>,</span>
      <br/>
      <span className={props.isLanding ? 'p2' : ''}>Together.</span>
    </h1>
  );
}

function Video(props) {
  return (
    <iframe 
        className='video'
        src="https://www.youtube.com/embed/iE7N3jgF4nU?rel=0&amp;color=white"
        allowFullScreen='1' />
  );
}

function Overview(props) {
  return (
    <div className='overview'>
      <p>
        <a target="_blank" href='https://en.wikipedia.org/wiki/Open_research'>Open research</a> is the practice 
        of conducting cumulative and collaborative 
        intellectual and scientific inquiry by 
        making research methodology, data, and 
        results immediately freely accessible for 
        others to reproduce and build upon. 
        Components of open research, such as open 
        data and open collaboration, have been 
        shown to accelerate research validation 
        and further discoveries to save thousands 
        of lives during epidemics, such as that 
        of the <a target="_blank" href='https://www.nature.com/news/data-sharing-make-outbreak-research-open-access-1.16966'>West Africa Ebola virus epidemic</a>, 
        and dramatically extend our understanding 
        of human biology through efforts such as 
        the <a target="_blank" href='https://genomemedicine.biomedcentral.com/articles/10.1186/gm483'>Human Genome Project</a>. In 
        addition to improving the health of our 
        species, such efforts have been 
        shown to improve our economy, as the 
        Human Genome Project <a target="_blank" href='https://www.nature.com/news/economic-return-from-human-genome-project-grows-1.13187#:~:text=The%20financial%20benefit%20of%20the,hovers%20near%20US%241%20trillion.'>is said</a> to 
        have helped generate nearly $1 trillion 
        in economic impact and over 4.3 million 
        job-years of employment thus far in the 
        United States alone.
      </p>
      <p>
        The Open Research Foundation is a Dutch 
        non-profit with the mission to increase 
        open research efforts among the world’s 
        researchers to accelerate scientific 
        discovery for positive global change. 
        Learn more about our mission <Link to='/mission'>here</Link>.
      </p>
    </div>
  );
}

function Subscribe(props) {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ _type: '', text: 'Subscribe'});
  const changeEmail = ref => {
    const email = ref.target.value;
    setEmail(email);
  }
  const subscribe = () => {
    const delayedStatusUpdate = async (_type, text) => {
      setTimeout(()=>{
        setStatus({ _type, text})
      }, 1000)
    }

    if(!validator.validate(email)){
      setStatus({ _type: 'error', text: 'Invalid Email' });
      delayedStatusUpdate('', 'Subscribe');
      return;
    }
    
    const db = firebase.firestore();
    setStatus({ _type: 'loading', text: 'Subscribing' });
    db.collection('subscribers').doc(email).set({}).then(_ =>{
      setStatus({ _type: 'success', text: 'Subscribed' });
      delayedStatusUpdate('', 'Subscribe');
      setEmail('');
    }).catch(err => {
      console.log(err);
      setStatus({ _type: 'red', text: 'Error' });
      delayedStatusUpdate('', 'Subscribe');
    });
  }
  
  return (
    <div className='subscribe-container'>
      <label>Subscribe to our newsletter</label>
      <form className='subscribe-wrapper'>
        <input 
          placeholder='Enter your email...' 
          type='email' 
          onChange={e => setEmail(e.target.value)} 
          value={email} 
          disabled={status._type === 'loading'}/>
        <div 
          onClick={subscribe.bind(this)} 
          className={'subscribe-button ' + status._type} 
          style={{backgroundColor: status.color}}>
          {status.text}
        </div>
      </form>
    </div>
  );
}

export default function About() {
  
  const isLanding = window.location.pathname == '/';

  return (
    <div className='about'>

      <div className={isLanding ? 'fade-in' : ''}>
        <Background 
          src={bg1} 
          srcName={"SARS-CoV-2"} 
          srcAuthor={"NIH"}/>
      </div>

      <div className='content-container'>
        <div className='content-wrapper'>
          <div className='content'>
            <div className={isLanding ? 'fade-down' : ''}>
              <Nav/>
            </div>
            <Header isLanding={isLanding}/>
            <div className={isLanding ? 'fade-up' : ''}>
              <Video/>
              <Overview/>
              <Subscribe/>
              <Footer/>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
}
